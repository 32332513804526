var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-card user" },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            "background-color-popup": _vm.colorx,
            title: "Usuario",
            active: _vm.popupActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupActive = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vx-card__collapsible-content vs-con-loading__container",
            },
            [
              _c("div", { staticClass: "vx-card__body" }, [
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("Nombre")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { name: "user.first_name" },
                        model: {
                          value: _vm.user.first_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "first_name", $$v)
                          },
                          expression: "user.first_name",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user.first_name"),
                              expression: "errors.has('user.first_name')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Requerido")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("Apellidos")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { name: "user.last_name" },
                        model: {
                          value: _vm.user.last_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "last_name", $$v)
                          },
                          expression: "user.last_name",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user.last_name"),
                              expression: "errors.has('user.last_name')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Requerido")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("Genero")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: { options: _vm.genders, name: "user.gender" },
                        model: {
                          value: _vm.user.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "gender", $$v)
                          },
                          expression: "user.gender",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user.gender"),
                              expression: "errors.has('user.gender')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Requerido")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("E-mail")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { name: "user.email" },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user.email"),
                              expression: "errors.has('user.email')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Requerido")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mb-6" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
                    _c("span", [_vm._v("Número teléfonico")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { name: "user.telephone" },
                        model: {
                          value: _vm.user.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "telephone", $$v)
                          },
                          expression: "user.telephone",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user.telephone"),
                              expression: "errors.has('user.telephone')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Requerido")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.percentajeForm > 0 && _vm.percentajeForm < 100
                  ? _c(
                      "div",
                      {},
                      [
                        _vm._v(
                          "\n          Espere un momento por favor ...\n          "
                        ),
                        _c("vs-progress", {
                          attrs: {
                            percent: _vm.percentajeForm,
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-2/3 w-full ml-auto" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mr-3 mb-2",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.submitForm.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Guardar")]
                      ),
                      _vm.user != {}
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "warning", type: "border" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetFormUser()
                                },
                              },
                            },
                            [_vm._v("Reiniciar\n              formulario")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm.role_name != "Cliente"
        ? _c(
            "div",
            {
              staticClass:
                "vx-card__collapsible-content vs-con-loading__container",
            },
            [
              _c(
                "div",
                { staticClass: "vx-card__body" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function ($event) {
                          return _vm.showForm()
                        },
                      },
                    },
                    [_vm._v("Crear usuario")]
                  ),
                  _vm.users.length > 0
                    ? _c(
                        "vs-table",
                        {
                          attrs: {
                            stripe: "",
                            "max-items": "10",
                            search: "",
                            data: _vm.users,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var data = ref.data
                                  return _vm._l(data, function (tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].first_name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  data[indextr].first_name
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].last_name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  data[indextr].last_name
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].email,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(data[indextr].email) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].telephone,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  data[indextr].telephone
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].status,
                                            },
                                          },
                                          [
                                            data[indextr].status == 0
                                              ? _c("div", [_vm._v("Inactivo")])
                                              : _vm._e(),
                                            data[indextr].status == 1
                                              ? _c("div", [_vm._v("Activo")])
                                              : _vm._e(),
                                            data[indextr].status == 2
                                              ? _c("div", [_vm._v("Archivado")])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "btn-group" },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editUser(
                                                      data[indextr]
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Editar")]
                                            ),
                                            data[indextr].status == 0
                                              ? _c(
                                                  "vs-button",
                                                  {
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.activeUser(
                                                          data[indextr].id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  Activar"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            data[indextr].status == 1
                                              ? _c(
                                                  "vs-button",
                                                  {
                                                    attrs: { color: "danger" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.inactiveUser(
                                                          data[indextr].id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  Inactivar"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  })
                                },
                              },
                            ],
                            null,
                            false,
                            891834574
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("h3", [
                              _vm._v(
                                "\n            Listado de usuarios\n          "
                              ),
                            ]),
                          ]),
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c(
                                "vs-th",
                                { attrs: { "sort-key": "first_name" } },
                                [_vm._v("\n            Nombres\n          ")]
                              ),
                              _c(
                                "vs-th",
                                { attrs: { "sort-key": "last_name" } },
                                [_vm._v("\n            Apellidos\n          ")]
                              ),
                              _c("vs-th", { attrs: { "sort-key": "email" } }, [
                                _vm._v("\n            Email\n          "),
                              ]),
                              _c(
                                "vs-th",
                                { attrs: { "sort-key": "telephone" } },
                                [_vm._v("\n            Teléfono\n          ")]
                              ),
                              _c("vs-th", { attrs: { "sort-key": "status" } }, [
                                _vm._v("\n            Estado\n          "),
                              ]),
                              _c("vs-th", [
                                _vm._v("\n            Acciones\n          "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("br"),
                  _c("vs-pagination", {
                    attrs: { total: _vm.users_count },
                    model: {
                      value: _vm.currentx,
                      callback: function ($$v) {
                        _vm.currentx = $$v
                      },
                      expression: "currentx",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }