<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card user">
    <vs-popup background-color="rgba(255,255,255,.6)" :background-color-popup="colorx" class="" title="Usuario"
      :active.sync="popupActive">
      <div class="vx-card__collapsible-content vs-con-loading__container">
        <div class="vx-card__body">
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Nombre</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="user.first_name" v-validate="'required'" name="user.first_name" />
              <span class="text-danger text-sm" v-show="errors.has('user.first_name')">Requerido</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Apellidos</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="user.last_name" v-validate="'required'" name="user.last_name" />
              <span class="text-danger text-sm" v-show="errors.has('user.last_name')">Requerido</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Genero</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <v-select v-model="user.gender" :options="genders" v-validate="'required'" name="user.gender"></v-select>
              <span class="text-danger text-sm" v-show="errors.has('user.gender')">Requerido</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>E-mail</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="user.email" v-validate="'required|email'" name="user.email" />
              <span class="text-danger text-sm" v-show="errors.has('user.email')">Requerido</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Número teléfonico</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vs-input class="w-full" v-model="user.telephone" v-validate="'required'" name="user.telephone" />
              <span class="text-danger text-sm" v-show="errors.has('user.telephone')">Requerido</span>
            </div>
          </div>
          <div class="" v-if="percentajeForm > 0 && percentajeForm < 100">
            Espere un momento por favor ...
            <vs-progress v-bind:percent="percentajeForm" color="primary"></vs-progress>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click.prevent="submitForm">Guardar</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="resetFormUser()" v-if="user != {}">Reiniciar
                formulario</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <div class="vx-card__collapsible-content vs-con-loading__container" v-if="role_name != 'Cliente'">
      <div class="vx-card__body">
        <vs-button color="primary" @click="showForm()" type="filled">Crear usuario</vs-button>
        <vs-table stripe max-items="10" search :data="users" v-if="users.length > 0">
          <template slot="header">
            <h3>
              Listado de usuarios
            </h3>
          </template>
          <template slot="thead">
            <vs-th sort-key="first_name">
              Nombres
            </vs-th>
            <vs-th sort-key="last_name">
              Apellidos
            </vs-th>
            <vs-th sort-key="email">
              Email
            </vs-th>
            <vs-th sort-key="telephone">
              Teléfono
            </vs-th>
            <vs-th sort-key="status">
              Estado
            </vs-th>
            <vs-th>
              Acciones
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].first_name">
                {{data[indextr].first_name}}
              </vs-td>

              <vs-td :data="data[indextr].last_name">
                {{data[indextr].last_name}}
              </vs-td>

              <vs-td :data="data[indextr].email">
                {{data[indextr].email}}
              </vs-td>

              <vs-td :data="data[indextr].telephone">
                {{data[indextr].telephone}}
              </vs-td>
              <vs-td :data="data[indextr].status">
                <div v-if="data[indextr].status == 0">Inactivo</div>
                <div v-if="data[indextr].status == 1">Activo</div>
                <div v-if="data[indextr].status == 2">Archivado</div>
              </vs-td>
                <div class="btn-group">
                  <vs-button color="primary" @click="editUser(data[indextr])">Editar</vs-button>
                  <vs-button color="primary" @click="activeUser(data[indextr].id)" v-if="data[indextr].status == 0">
                    Activar</vs-button>
                  <vs-button color="danger" @click="inactiveUser(data[indextr].id)" v-if="data[indextr].status == 1">
                    Inactivar</vs-button>
                </div>
            </vs-tr>
          </template>
        </vs-table>
        <br>
        <vs-pagination :total="users_count" v-model="currentx"></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import api from '../../../mixins/api.js'
import { Validator } from 'vee-validate';
import themeConfig from '@/../themeConfig.js';
import { mapGetters } from 'vuex'
import axios from 'axios'
const VxTour = () => import('@/components/VxTour.vue')
const dict = {
  custom: {
    first_name: {
      required: 'Nombre es requerido'
    },
    last_name: {
      required: 'Apellido requerido'
    },
    gender: {
      required: 'Genero requerido'
    },
    email: {
      required: 'Email requerido'
    },
    telephone: {
      required: 'Teléfono requerido'
    },
  }
};
Validator.localize('en', dict);
export default {
  mixins: [api],
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      role_name: '',
      users_count: 0,
      currentx: 1,
      colorx: "rgba(255,255,255,255)",
      popupActive: false,
      create: true,
      percentajeForm: 0,
      genders: [
        { id: 0, label: 'Femenino' },
        { id: 1, label: 'Masculino' }
      ],
      user: {},
      users: [],
      disableThemeTour: themeConfig.disableThemeTour,
      steps: [
        {
          target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.user > div > div > button > span.vs-button-text.vs-button--text',
          content: 'Crea un nuevo usuario'
        },
        {
          target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.user > div > div > div.vs-component.vs-con-table.stripe.vs-table-primary > div > div.vs-con-tbody.vs-table--tbody > table > tr:nth-child(3) > td:nth-child(7) > span > div > button.vs-component.vs-button.vs-button-primary.vs-button-filled',
          content: 'Editar usuario'
        },
        {
          target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.user > div > div > div.vs-component.vs-con-table.stripe.vs-table-primary > div > div.vs-con-tbody.vs-table--tbody > table > tr:nth-child(3) > td:nth-child(7) > span > div > button.vs-component.vs-button.vs-button-danger.vs-button-filled',
          content: 'Inactivar usuario'
        }
      ]
    }
  },
  components: {
    vSelect,
    VxTour
  },
  watch: {
    currentx: function () {
      console.log('Change ' + this.currentx)
      this.getUsers();
    }
  },
  methods: {
    showForm() {
      this.popupActive = true;
      this.resetFormUser();
    },
    editUser(user) {
      this.popupActive = true;
      this.user = user;
      this.create = false;
    },
    resetFormUser() {
      this.user = {}
      this.create = true;
    },
    getUsers() {
      var user_id = this.userInfo.user.id
      var path = "/api/v1/users?user_id=" + user_id + "&page=" + this.currentx;
      this.requestGetWithoutContent(this.url() + path, {}).then(response => {
        if (response.data.success == true) {
          this.users = response.data.users
          this.users_count = Math.ceil(response.data.users_count / 10)
        } else {
          //console.log("error -> " + response.data.message);
        }
      }, (err) => {
        console.log('err users -> ' + JSON.stringify(err))
      })
    },
    activeUser(user_id) {
      var current_user = this.userInfo.user.id
      var path = "/api/v1/users/" + user_id + "/activate?current_user=" + current_user
      this.requestGetWithoutContent(this.url() + path, { user: this.user }, this.headers()).then(response => {
        var message = ""
        if (response.data.success == true) {
          this.getUsers();
          message = "Usuario activado"
        } else {
          message = "No se pudo activar el usuario"
        }
        this.$vs.notify({
          title: 'Mensaje de Globalwoork',
          text: message,
          color: 'primary',
          position: 'bottom-left'
        })
      }, (err) => {
        console.log('err users -> ' + JSON.stringify(err))
        this.percentajeForm = 100
      })
    },
    inactiveUser(user_id) {
      var current_user = this.userInfo.user.id
      var path = "/api/v1/users/" + user_id + "/inactivate?current_user=" + current_user
      this.requestGetWithoutContent(this.url() + path, { user: this.user }, this.headers()).then(response => {
        var message = ""
        console.log(response)
        if (response.status == 200) {
          this.getUsers();
          message = response.data.response.message
          this.$vs.notify({
            title: 'Mensaje de Globalwoork',
            text: message,
            color: 'primary',
            position: 'bottom-left',
          })
        }
      }, (err) => {
        this.$vs.notify({
          title: 'Mensaje de Globalwoork',
          text: 'No se pudo inactivar el usuario',
          color: 'danger',
          position: 'bottom-left',
        })
        console.log('err users -> ' + JSON.stringify(err))
        this.percentajeForm = 100
      })
    },
    submitForm() {
      this.percentajeForm = 30
      this.$validator.validateAll().then(result => {
        this.percentajeForm = 60
        if (result) {
          let user_id = this.userInfo.user.id
          let client_id = this.userInfo.user.client_id
          let current_user = user_id
          let path = this.create ? "/api/v1/users" : "/api/v1/users/" + this.user.id

          this.user.gender = this.user.gender.id
          this.user["client_id"] = client_id
          if (this.create) {
            this.createUser(path, current_user)
          } else {
            this.editUserGB(path, current_user)
          }
        } else {
          this.percentajeForm = 100
        }
      })
    },

    async createUser(pathUsers, current_user) {
      let email = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8)
      let password = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm3, this.url()).toString(this.CryptoJS.enc.Utf8)

      let pathIntranet = process.env.VUE_APP_ROOT_INTRANET
      let pathLogin = 'api/v1/login'
      let message = ''
      try {
        let { data } = await axios({
          url: pathIntranet + pathLogin,
          method: 'POST',
          data: {
            email,
            password
          }
        })
        if (data.success === true) {
          let { token } = data.data
          let response = await axios({
            url: pathIntranet + pathUsers,
            method: 'POST',
            data: {
              user: this.user,
              current_user
            },
            headers: {
              token
            }
          })
          if (response.data.success === true) {
            message = 'El usuario se guardó con éxito.'
            this.popupActive = false
            this.getUsers()
            this.resetFormUser()
          } else {
            message = 'No se pudo crear el usuario, intentalo nuevamente'
          }
        } else {
          message = 'No tienes autorización para crear el usuario. Vuelve a iniciar sesión.'
        }
        this.percentajeForm = 100
        this.$vs.notify({
          title: 'Mensaje de Globalwork',
          text: message,
          color: 'primary',
          position: 'bottom-left'
        })
      } catch (error) {
        console.error(error)
      }
    },
    editUserGB(path, current_user) {
      var gender_copy = this.user.gender
      this.requestPut(this.url() + path, { user: this.user, current_user: current_user }).then(response => {
        var message = ""
        if (response.data.success == true) {
          this.getUsers();
          message = "Datos guardados"
          this.resetFormUser();
          this.popupActive = false;
          this.percentajeForm = 100
        } else {
          this.percentajeForm = 100
          this.user.gender = gender_copy
          message = "No se pudo guardar la información"
        }
        this.$vs.notify({
          title: 'Mensaje de Globalwoork',
          text: message,
          color: 'primary',
          position: 'bottom-left'
        })
      }, (err) => {
        console.log('err users -> ' + JSON.stringify(err))
        this.percentajeForm = 100
      })
      this.errors.clear();
    }
  },
  created: function () {
    if (this.userInfo == null) {
      window.localStorage.clear()
      this.$router.push('/pages/login')
    } else {
      this.role_name = this.userInfo.user.role_name
      this.getUsers();
    }
  }
}
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }

    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media(max-width: 576px) {

    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>